import { atom, useAtomValue } from "jotai"
import { atomWithQuery } from "jotai/query"
import { useQuery } from "react-query"
import { event } from "../utils/event"
import { fetcher } from "../utils/fetcher"
import { socketNotificationAtom } from "./notificationAtom"
import { apiTokenAtom } from "./userAtom"

export const applicationKeys = {
  single: (id) => ["application", id],
}

export const activeApplicationIdAtom = atom(null)

export async function fetchApplication({
  signal,
  queryKey: [, applicationId],
}) {
  try {
    const res = await fetcher({
      method: "GET",
      url: `/application/${applicationId}`,
      signal,
    })

    if (res?.status === 200) {
      return res?.data?.data
    }
  } catch (error) {
    // console.log({ failed_application: error })
  }
}
export const applicationAtom = atomWithQuery((get) => ({
  queryKey: applicationKeys.single(get(activeApplicationIdAtom)),
  queryFn: fetchApplication,
  enabled: Boolean(apiTokenAtom) && Boolean(activeApplicationIdAtom),
}))
export const useApplicationQuery = () => {
  const applicationId = useAtomValue(activeApplicationIdAtom)
  const isApiTokenAvailable = useAtomValue(apiTokenAtom)
  const applicationQuery = useQuery(
    applicationKeys.single(applicationId),
    fetchApplication,
    { enabled: Boolean(isApiTokenAvailable) && Boolean(applicationId) }
  )
  return applicationQuery
}

export const appliedJobsAtom = atom((get) => {
  const fetchAppliedJobs = async () => {
    try {
      const res = await fetcher({
        method: "GET",
        url: `/candidate/${me?._id}/apply_vacancy`,
      })

      // console.log({ applied_jobs: res?.data })

      if (res?.data?.status === "success") {
        return res?.data?.data
      }
    } catch (error) {
      // console.log({ failed_fetch_applied_jobs: error })
      return []
    }
  }
  if (fetcher?.defaults?.headers[`Authorization`]) {
    fetchAppliedJobs()
  }
})

export const cancelApplicationAtom = atom(
  null,
  (_get, set, { id, notes, callback }) => {
    const cancelJobApplication = async () => {
      try {
        const res = await fetcher({
          method: "PUT",
          url: `/admin/update_application/${id}`,
          data: {
            status: "99",
            failed_reason: {
              id: "Cancelled by candidate",
              jp: "Cancelled by candidate",
            },
            failed_notes: notes,
          },
        })

        // console.log({ CANCEL_APPLICATION: res?.data })
        if (res?.data?.status === "success") {
          if (callback && typeof callback === "function") {
            callback()
          }
        }
      } catch (error) {
        // console.log({ failed_cancel_apply: error })
      }
    }
    if (id && fetcher?.defaults?.headers[`Authorization`]) {
      cancelJobApplication()
    }
  }
)

export const applyJobAtom = atom(
  null,
  (_get, set, { id, userId, onSuccess }) => {
    const socketNotif = _get(socketNotificationAtom)

    const applyJob = async () => {
      try {
        const res = await fetcher({
          method: "POST",
          url: `/candidate/${userId}/apply_vacancy`,
          data: { id_vacancy: id },
        })

        // console.log({ apply: res?.data })

        if (res?.status === 200) {
          await socketNotif.emit(event.application.apply, {
            candidateId: userId,
            jobId: id,
            jobType: res?.data?.data?.type,
            applicationId: res?.data?.data?._id,
            companyId: res?.data?.data?.id_company,
          })

          if (onSuccess && typeof onSuccess === "function") {
            onSuccess()
          }
        }
      } catch (error) {
        alert("Gagal mengirim lamaran! Silakan coba lagi")
        // console.log({ failed_apply_job: error })
      }
    }
    if (id && fetcher?.defaults?.headers[`Authorization`]) {
      applyJob()
    }
  }
)

export const updateApplicationatom = atom(
  null,
  async (_get, set, { applicationId, data, onSuccess, onError }) => {
    try {
      const res = await fetcher({
        method: "PUT",
        url: `/pic/update_application/${applicationId}`,
        data,
      })

      if (res?.status === 200) {
        await set(applicationAtom, { type: "refetch" })
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess(res?.data)
        }
      }
    } catch (error) {
      if (onError && typeof onError === "function") {
        onError()
      }
    }
  }
)
