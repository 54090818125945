import React, { useEffect, useState } from 'react'
import { useSswIndustriesQuery } from '../atoms/setupAtom'

export const useSsw = (initial) => {
  const { data: sswIndustries } = useSswIndustriesQuery()

  // InitialSsw
  const [status, setStatus] = useState(initial?.status) // 0=no, 1=less-3y, 2=on-going, 3=alumni
  const [industry, setIndustry] = useState(initial?.industry)
  const [occupation, setOccupation] = useState(initial?.occupation)
  const [certificate, setCertificate] = useState(initial?.certificate)

  useEffect(() => {
    if (sswIndustries?.length > 0) {
      setIndustry(sswIndustries[0])
    }
  }, [sswIndustries])

  return {
    status,
    setStatus,
    industry,
    setIndustry,
    occupation,
    setOccupation,
    certificate,
    setCertificate,
  }
}
