import React, { useState } from 'react'
import { bulan } from '../utils/data'

export const useWork = () => {
  // InitialTitp
  const [work, setWork] = useState('0') // [0=no, 1=on-going, 2=pernah]
  const [company, setCompany] = useState('')
  const [industry, setIndustry] = useState('')
  const [startMonth, setStartMonth] = useState(bulan[0])
  const [startYear, setStartYear] = useState('')
  const [endMonth, setEndMonth] = useState(bulan[0])
  const [endYear, setEndYear] = useState('')
  const [description, setDescription] = useState('')
  const [document, setDocument] = useState('')

  const resetInitialWork = () => {
    setWork('0')
    setCompany('')
    setIndustry('')
    setStartMonth(bulan[0])
    setStartYear('')
    setEndMonth(bulan[0])
    setEndYear('')
    setDescription('')
    setDocument('')
  }

  return {
    resetInitialWork,
    work,
    setWork,
    company,
    setCompany,
    industry,
    setIndustry,
    startMonth,
    setStartMonth,
    startYear,
    setStartYear,
    endMonth,
    setEndMonth,
    endYear,
    setEndYear,
    description,
    setDescription,
    document,
    setDocument,
  }
}
