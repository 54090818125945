import React, { useEffect } from "react"
import { useQuery } from "react-query"
import { atomWithQuery } from "jotai/query"
import { atom, useAtomValue } from "jotai"

import { authenticateFetcher, fetcher } from "../utils/fetcher"

export const userKeys = {
  profile: ["user-profile"],
}

export const apiTokenAtom = atom(null)
export const roleAtom = atom("candidate")

export async function fetchUser({ signal }) {
  try {
    const res = await fetcher({ method: "GET", url: "/profile", signal })
    if (res?.status === 200) {
      return res?.data
    }
    return null
  } catch (error) {
    console.log({ failed_fetch_user: error })
    throw new Error(error)
  }
}

export const userAtom = atomWithQuery((get) => ({
  queryKey: userKeys.profile,
  queryFn: fetchUser,
  enabled: Boolean(get(apiTokenAtom)),
}))

export const useUserQuery = () => {
  const isApiTokenAvailable = useAtomValue(apiTokenAtom)
  const profile = useQuery(userKeys.profile, fetchUser, {
    enabled: Boolean(isApiTokenAvailable),
  })

  return profile
}

export const updateUserAtom = atom(
  null,
  async (_get, set, { userId, data, onSuccess, onError }) => {
    let me
    if (!userId) {
      me = _get(userAtom)
    }

    try {
      const res = await fetcher({
        method: "PUT",
        url: `/candidate/${userId ?? me?._id}`,
        data,
      })
      console.log({ data: res?.data })

      if (res?.status === 200) {
        set(userAtom, { type: "refetch" })
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess(res?.data?.data)
        }
      }
    } catch (error) {
      console.log({ failed_update_resume: error })
      if (onError && typeof onError === "function") {
        onError(error)
      }
    }
  }
)

export const updatePasswordAtom = atom(
  null,
  async (_get, set, { data, onSuccess, onError }) => {
    let me = _get(userAtom)

    try {
      const res = await fetcher({
        method: "PUT",
        url: `/users/password/${me._id}`,
        data: {
          email: me.email,
          ...data,
        },
      })

      if (res?.status === 200) {
        set(userAtom, { type: "refetch" })
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess(res?.data?.data)
        }
      }
    } catch (error) {
      console.log({ failed_update_password: error })
      if (onError && typeof onError === "function") {
        onError(error)
      }
    }
  }
)

export const registerAtom = atom(
  null,
  async (_get, set, { user, onSuccess, onError }) => {
    try {
      let res = await fetcher({
        method: "POST",
        url: `/candidate`,
        data: { ...user, verified: "0" },
      })

      if (res?.status === 201 || res?.status === 200) {
        // console.log({ reg: res?.data })

        if (res?.data?.token) {
          authenticateFetcher(res?.data?.token)
        }

        if (onSuccess && typeof onSuccess === "function") {
          await onSuccess({
            email: user?.email,
            password: user?.password,
            candidateId: res?.data?.data?._id,
            apiToken: res?.data?.token,
          })
        }
      }
    } catch (error) {
      alert(
        "Gagal Register! Periksa kembali email dan password yang anda input!"
      )
      if (onError && typeof onError === "function") {
        onError(error)
      }
      // console.log({ error })
    }
  }
)
