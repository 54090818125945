import { atom, useAtomValue } from "jotai"
import { event } from "../utils/event"
import { io } from "socket.io-client"
import produce from "immer"
import { atomWithQuery } from "jotai/query"
import { apiTokenAtom, userAtom } from "./userAtom"
import { fetchSocket } from "../utils/fetcher"
import { useQuery } from "react-query"

export const notificationChannelUrl =
  process.env.NEXT_PUBLIC_SOCKET_HOST + "/notification"
export const notificationChannelUrlAtom = atom(
  process.env.NEXT_PUBLIC_SOCKET_HOST + "/notification"
)

export const notificationKeys = {
  all: (roomId) => ["notifications", roomId],
}

export const socketNotificationAtom = atom(null)
export const notificationRoomAtom = atom(null)
export const notificationsAtom = atom([])

export const addNotificationAtom = atom(null, (_get, set, { notification }) => {
  let notifications = _get(notificationsAtom)
  let updated = produce(notifications, (draft) => {
    draft.unshift(notification)
  })
  set(notificationsAtom, updated)
})

export const hasUnreadNotificationsAtom = atom((get) => {
  let notifs = get(notificationsAtom)
  let unreads = notifs.filter((n) => n.isRead === false)
  // console.log({ unreads, notifs })
  if (unreads?.length > 0) {
    return true
  } else {
    return false
  }
})

export const readNotificationAtom = atom(
  null,
  async (_get, set, { notificationId, onSuccess, onError }) => {
    const socketNotif = _get(socketNotificationAtom)
    if (socketNotif) {
      socketNotif.emit(
        event.notification.read,
        { notificationId },
        (isRead) => {
          if (isRead) {
            set(notificationsQueryAtom, { type: "refetch" })
            let updated = produce(_get(notificationsAtom), (draft) => {
              let index = draft.findIndex((n) => n?._id === notificationId)
              if (index !== -1) {
                draft[index] = { ...draft.index, isRead: true }
              }
            })
            set(notificationsAtom, updated)
            if (onSuccess && typeof onSuccess === "function") {
              onSuccess()
            }
          } else {
            if (onError && typeof onError === "function") {
              onError()
            }
          }
        }
      )
    }
  }
)

export const fetchNotifications = async ({ signal, queryKey: [, roomId] }) => {
  try {
    const res = await fetchSocket({
      method: "GET",
      url: `/notification/${roomId}`,
      signal,
    })

    // console.log({ notifications: res?.data?.data })

    if (res?.status === 200) {
      return res?.data?.data
    }
  } catch (error) {
    console.log({ failed_fetch_notifications: error })
    throw new Error(error)
  }
}
export const notificationsQueryAtom = atomWithQuery((get) => ({
  queryKey: notificationKeys.all(get(userAtom)?._id),
  queryFn: fetchNotifications,
  enabled: Boolean(get(apiTokenAtom)),
  staleTime: Infinity,
  cacheTime: Infinity,
  refetchOnWindowFocus: false,
}))
export const useNotificationsQuery = () => {
  const user = useAtomValue(userAtom)
  const isApiTokenAvailable = useAtomValue(apiTokenAtom)
  const notificationsQuery = useQuery(
    notificationKeys.all(user?._id),
    fetchNotifications,
    {
      enabled: Boolean(isApiTokenAvailable),
    }
  )

  return notificationsQuery
}

export const registerCandidateAtom = atom(
  null,
  async (_get, set, { candidateId, apiToken }) => {
    const socketNotif = io(_get(notificationChannelUrlAtom), {
      query: {
        userId: candidateId,
        role: "candidate",
        apiToken,
      },
    })

    set(socketNotificationAtom, socketNotif)
    if (socketNotif?.disconnected) {
      socketNotif.connect()
    }

    if (socketNotif) {
      socketNotif.emit(event.candidate.register, { candidateId })
    }
  }
)

export const emitRequestVerificationAtom = atom(
  null,
  async (_get, set, { candidateId }) => {
    const socketNotif = _get(socketNotificationAtom)
    if (socketNotif) {
      socketNotif.emit(event.resume.request, { candidateId })
    }
  }
)
