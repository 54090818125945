import { atom, useAtomValue } from "jotai"

import { userAtom, useUserQuery } from "./userAtom"

import { fetcher } from "../utils/fetcher"
import { bulan } from "../utils/data"

export const educationsAtom = atom([])

export const educationAtom = atom(async (get) => {
  const educations = get(educationsAtom)
  if (educations?.length > 0) {
    let edu = educations[0]
    return {
      degree: edu?.degree,
      major: edu?.major,
      school: edu?.school,
      diploma: edu?.diploma,
      graduationMonth: edu?.graduation_month ? edu?.graduation_month : bulan[0],
      graduationYear: edu?.graduation_year,
    }
  }
})

export const setInitialEducationsAtom = atom(
  null,
  async (_get, set, initialData) => {
    let initial = initialData ? initialData : await _get(userAtom)?.education
    set(educationsAtom, initial)
  }
)
export const useEducationQuery = () => {
  const { data: me } = useUserQuery()
  const education = me?.education?.[0]
  return education
}

export const updateEducationAndAddWorkAtom = atom(
  null,
  async (_get, set, { education, work, onSuccess, onError }) => {
    try {
      const [resEducation, resWork] = await Promise.all([
        fetcher({
          method: "POST",
          url: `/candidate/update/education`,
          data: { education: education },
        }),
        fetcher({
          method: "POST",
          url: `/candidate/add/work_exp`,
          data: { ...work },
        }),
      ])

      if (resEducation?.status === 200 && resWork?.status === 200) {
        set(userAtom, { type: "refetch" })
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess()
        }
      }
    } catch (error) {
      // console.log({ failed_update_education_and_add_work: error })
      if (onError && typeof onError === "function") {
        onError()
      }
    }
  }
)

export const updateEducationAtom = atom(
  null,
  async (_get, set, { me, data, onSuccess, onError }) => {
    try {
      let res = await fetcher({
        method: "PUT",
        url: `/candidate/${me?._id}/education`,
        data,
      })

      if (res.status === 200) {
        set(userAtom, { type: "refetch" })
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess(res?.data?.data)
        }
      }
    } catch (error) {
      // console.log({ failed_update_education: error })
      alert("Gagal update riwayat pendidikan!")
      if (onError && typeof onError === "function") {
        onError(error)
      }
    }
  }
)
