import { useAtom } from "jotai"
import React, { useEffect } from "react"
import { documentFocusAtom } from "../atoms/setupAtom"

export const useWindowFocus = () => {
  const [focus, setFocus] = useAtom(documentFocusAtom)

  function onFocus() {
    setFocus(true)
  }
  function onBlur() {
    setFocus(false)
  }

  useEffect(() => {
    window.addEventListener("focus", onFocus)
    window.addEventListener("blur", onBlur)

    return () => {
      window.removeEventListener("focus", onFocus)
      window.removeEventListener("blue", onBlur)
    }
  }, [])

  return focus
}
