import React, { useEffect } from "react"

/**
 *
 * @param {String} elementId
 * @param {String} shownHeight
 * @param {String} hiddenHeight
 * @returns
 */
export const useStickyNavbar = (elementId, shownHeight, hiddenHeight) => {
  useEffect(() => {
    let prevScrollpos = window.pageYOffset
    let navbar = document.querySelector(elementId)

    if (navbar) {
      window.onscroll = function () {
        let currentScrollPos = window.pageYOffset

        if (
          (navbar && prevScrollpos > currentScrollPos) ||
          window.pageYOffset <= 0
        ) {
          navbar.style.top = shownHeight
        } else {
          navbar.style.top = hiddenHeight
        }

        prevScrollpos = currentScrollPos
      }
    }

    return () => {
      window.onscroll = ""
    }
  }, [])

  return null
}
