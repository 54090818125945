import produce from "immer"
import { atom, useAtomValue } from "jotai"
import { atomWithInfiniteQuery, queryClientAtom } from "jotai/query"
import { useQuery, useInfiniteQuery } from "react-query"

import { event } from "../utils/event"
import { fetchSocket } from "../utils/fetcher"
import { userAtom, useUserQuery } from "./userAtom"

export const chatDialogAtom = atom(false)

export const chatChannelUrl = process.env.NEXT_PUBLIC_SOCKET_HOST + "/chat"
export const chatChannelUrlAtom = atom(
  process.env.NEXT_PUBLIC_SOCKET_HOST + "/chat"
)

export const socketChatAtom = atom(null)
export const chatRoomAtom = atom({ status: "loading", roomId: null })
export const activeChatRoomIdAtom = atom((get) =>
  get(chatRoomAtom)?.status !== "loading"
    ? get(chatRoomAtom)?.roomId
    : "loading"
)

export const updateRoomLatestMessage = atom(
  null,
  (_get, set, { roomId, roomType, latestChat }) => {
    let room = _get(chatRoomAtom)
    if (!room || roomId !== room?.roomId || roomType !== room?.roomType) {
      return
    }
    let newRoom = { ...room, latestChat }
    set(chatRoomAtom, newRoom)
  }
)

export const unreadChatsAtom = atom([])
export const addUnreadChatAtom = atom(null, (_get, set, unreadChat) => {
  let updatedData = [unreadChat, ..._get(unreadChatsAtom)]
  set(unreadChatsAtom, updatedData)
})
export const createUnreadChatsAtom = atom(
  null,
  (_get, set, { roomId, chat }) => {
    const me = _get(userAtom)
    const socketChat = _get(socketChatAtom)

    if (chat?.sender?._id !== me?._id) {
      socketChat.emit(
        event.chat.createUnread,
        {
          room: roomId,
          chat: chat?._id,
          user: me?._id,
          role: me?.role,
        },
        (response) => {
          let updatedData = [response, ..._get(unreadChatsAtom)]
          set(unreadChatsAtom, updatedData)
        }
      )
    }
  }
)
export const removeUnreadChatAtom = atom(
  null,
  (_get, set, { roomId, chatId }) => {
    const me = _get(userAtom)
    const socketChat = _get(socketChatAtom)
    const unreadChats = _get(unreadChatsAtom)

    let index = unreadChats.findIndex((c) => c?.chatId === chatId)
    if (index !== -1) {
      console.log({ READ: chatId })

      socketChat.emit(
        event.chat.deleteUnread,
        {
          roomId,
          chatId,
          userId: me?._id,
        },
        (response) => {
          let updatedData = produce(_get(unreadChatsAtom), (draft) => {
            let index = draft.findIndex((d) => d?.chatId === response?.chatId)
            if (index !== -1) {
              draft.splice(index, 1)
            }
          })
          set(unreadChatsAtom, updatedData)
        }
      )
    }
  }
)
export const updateUnreadChatsAtom = atom(
  null,
  (_get, set, { roomId, chat }) => {
    let newData = { ..._get(unreadChatsAtom) }
    newData[roomId] = chat
    set(unreadChatsAtom, newData)
  }
)

export const chatsLimitAtom = atom(25)
export const chatsKeyAtom = atom((get) => {
  return ["infinite-chat", get(activeChatRoomIdAtom), get(chatsLimitAtom)]
})
const fetchChats = async ({
  signal,
  pageParam = 1,
  queryKey: [, roomId, limit],
}) => {
  try {
    const res = await fetchSocket({
      method: "GET",
      url: "/chat",
      signal,
      params: {
        roomId,
        limit,
        page: pageParam,
      },
    })

    if (res?.status === 200) {
      return res?.data
    }
  } catch (error) {
    if (error?.code === "ERR_CANCELED") {
      return
    }
    console.log({ failed_infinite_chats: error })
    return null
  }
}
export const useChatsQuery = () => {
  const chatQueryKey = useAtomValue(chatsKeyAtom)
  const chatsInfiniteQuery = useInfiniteQuery(chatQueryKey, fetchChats, {
    getNextPageParam: (lastPage) => {
      return lastPage?.hasNext ? Number(lastPage?.page) + 1 : undefined
    },
  })
  return chatsInfiniteQuery
}
export const mutateChatsAtom = atom(null, async (_get, set, newChat) => {
  const queryClient = _get(queryClientAtom)
  const chatQueryKey = _get(chatsKeyAtom)

  const oldPagesArray = queryClient.getQueryData(chatQueryKey)

  const newPagesArray = [...oldPagesArray?.pages]
  newPagesArray[0].data = [newChat, ...newPagesArray?.[0]?.data]

  // console.log({ MUTATE: newPagesArray })

  queryClient.setQueryData(chatQueryKey, (data) => {
    return {
      pageParams: data?.pageParams,
      pages: newPagesArray,
    }
  })
})

export const sendChatAtom = atom(
  null,
  (get, set, { body, onSuccess, onError }) => {
    const me = get(userAtom)
    const socketChat = get(socketChatAtom)
    const roomId = get(activeChatRoomIdAtom)
    const room = get(chatRoomAtom)

    if (socketChat) {
      socketChat.emit(
        event.room.newChat,
        {
          roomId,
          category: "text",
          body,
          sender: me?._id,
          role: me?.role,
          roomType: room?.roomType,
          room: {
            title: room?.meta?.title,
            image: room?.meta?.image,
          },
        },
        (chat) => {
          if (chat) {
            if (onSuccess && typeof onSuccess === "function") {
              onSuccess(chat)
            }
          }
        }
      )
    }
  }
)

export const chatRoomParticipantsAtom = atom([])
export const updateRoomParticipantsAtom = atom(
  null,
  (_get, set, { roomId, users }) => {
    // console.log('Someone entering the room...')
    set(chatRoomParticipantsAtom, users)
  }
)
export const enterChatRoomAtom = atom(
  null,
  async (get, set, { roomId, user }) => {
    const socketChat = get(socketChatAtom)
    socketChat.emit(event.room.enter, {
      roomId: roomId ?? get(activeChatRoomIdAtom),
      user: {
        _id: user?._id,
        role: user?.role,
        name: user?.name,
        photo: user?.photo,
      },
    })
  }
)
export const leaveChatRoomAtom = atom(
  null,
  async (get, set, { roomId, user }) => {
    const socketChat = get(socketChatAtom)
    socketChat.emit(event.room.leave, {
      roomId: roomId ?? get(activeChatRoomIdAtom),
      user: {
        _id: user?._id,
        role: user?.role,
        name: user?.name,
        photo: user?.photo,
      },
    })
  }
)

export const isTypingAtom = atom({})
export const updateTypersAtom = atom(null, (_get, set, { roomId, users }) => {
  const me = _get(userAtom)
  const processedUsers = produce(users, (draft) => {
    let index = draft.findIndex((d) => d?._id === me?._id)
    if (index !== -1) {
      draft.splice(index, 1)
    }
  })
  let newData = { ..._get(isTypingAtom) }
  newData[roomId] = processedUsers
  set(isTypingAtom, newData)
})

export const startTypingAtom = atom(null, (get, set, id) => {
  const me = get(userAtom)
  const socketChat = get(socketChatAtom)
  const roomId = id ? id : get(activeChatRoomIdAtom)

  socketChat.emit(event.room.startTyping, {
    roomId,
    user: { _id: me?._id, role: me?.role, name: me?.name, photo: me?.photo },
  })
})
export const stopTypingAtom = atom(null, (get, set, id) => {
  const me = get(userAtom)
  const socketChat = get(socketChatAtom)
  const roomId = id ? id : get(activeChatRoomIdAtom)

  socketChat.emit(event.room.stopTyping, {
    roomId,
    user: { _id: me?._id, role: me?.role, name: me?.name, photo: me?.photo },
  })
})

const fetchRoomQuery = async ({ queryKey: [roomId], signal }) => {
  try {
    const res = await fetchSocket({
      method: "GET",
      url: "/chat-room",
      signal,
      params: { roomId },
    })

    console.log({ CHATROOM: res })
    if (res?.status === 200) {
      return res?.data
    }
  } catch (error) {
    console.log({ error })
  }
}

export const useChatRoomQuery = () => {
  const { data: me } = useUserQuery()
  const chatRoom = useQuery([me?._id, "single-room"], fetchRoomQuery, {
    enabled: Boolean(me?._id),
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: "1000 * 60 * 60 * 12",
    staleTime: "Infinity",
  })

  return chatRoom
}
