import { atom, useAtom, useAtomValue } from "jotai"
import { useCallback } from "react"
import { atomWithQuery } from "jotai/query"
import { useQuery } from "react-query"
import { fetcher } from "../utils/fetcher"

const setupKeys = {
  prefectures: ["prefectures"],
  titpIndustries: ["titp-industries"],
  titpOccupations: ["titp-occupations"],
  sswIndustries: ["ssw-industries"],
  sswOccupations: ["ssw-occupations"],
}
const setupOptions = {
  staleTime: Infinity,
  cacheTime: Infinity,
  // cacheTime: 7 * 24 * 60 * 60 * 1000, // 30 days
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
}

export const contactFabAtom = atom(true)
export const useContactFab = () => {}
export const documentFocusAtom = atom(false)

export async function fetchPrefectures({ signal }) {
  try {
    let res = await fetcher({
      method: "GET",
      url: "/prefecture",
      signal,
    })
    if (res?.status === 200) {
      return res?.data?.data
    }
  } catch (error) {
    console.error({ failed_fetch_prefectures: error })
  }
}
export const prefecturesAtom = atomWithQuery((get) => ({
  queryKey: setupKeys.prefectures,
  queryFn: fetchPrefectures,
  ...setupOptions,
}))
export const usePrefecturesQuery = () => {
  const prefectureQuery = useQuery(setupKeys.prefectures, fetchPrefectures, {
    setupOptions,
  })
  return prefectureQuery
}

export async function fetchTitpIndustries({ signal }) {
  try {
    let res = await fetcher({
      method: "GET",
      url: `/job_industry_titp`,
      signal,
    })
    if (res?.status === 200) {
      if (typeof window !== "undefined") {
        window.titpIndustries = res?.data?.data
      }
      return res?.data?.data
    }
  } catch (error) {
    console.log({ failed_fetch_titp_industries: error })
  }
}
export const titpIndustriesAtom = atomWithQuery(() => ({
  queryKey: setupKeys.titpIndustries,
  queryFn: fetchTitpIndustries,
  ...setupOptions,
}))
export const useTitpIndustriesQuery = () => {
  const titpIndustriesQuery = useQuery(
    setupKeys.titpIndustries,
    fetchTitpIndustries,
    {
      setupOptions,
    }
  )
  return titpIndustriesQuery
}

export async function fetchTitpOccupations({ signal }) {
  try {
    let res = await fetcher({
      method: "GET",
      url: "/job_occupation_titp",
      signal,
    })
    if (res?.status === 200) {
      if (typeof window !== "undefined") {
        window.titpOccupations = res?.data?.data
      }
      return res?.data?.data
    }
  } catch (error) {
    console.log({ failed_fetch_titp_occupations: error })
  }
}
export const titpOccupationsAtom = atomWithQuery(() => ({
  queryKey: setupKeys.titpOccupations,
  queryFn: fetchTitpOccupations,
  ...setupOptions,
}))
export const useTitpOccupationsQuery = () => {
  const titpOccupationsQuery = useQuery(
    setupKeys.titpOccupations,
    fetchTitpOccupations,
    {
      setupOptions,
    }
  )
  return titpOccupationsQuery
}

export async function fetchSswIndustries({ signal }) {
  try {
    let res = await fetcher({
      method: "GET",
      url: `/job_industry`,
      signal,
    })
    if (res?.status === 200) {
      if (typeof window !== "undefined") {
        window.sswIndustries = res?.data?.data
      }
      return res?.data?.data
    }
  } catch (error) {
    console.log({ failed_fetch_ssw_industries: error })
  }
}
export const sswIndustriesAtom = atomWithQuery(() => ({
  queryKey: setupKeys.sswIndustries,
  queryFn: fetchSswIndustries,
}))
export const useSswIndustriesQuery = () => {
  const sswIndustriesQuery = useQuery(
    setupKeys.sswIndustries,
    fetchSswIndustries,
    {
      setupOptions,
    }
  )
  return sswIndustriesQuery
}

export async function fetchSswOccupations({ signal }) {
  try {
    let res = await fetcher({
      method: "GET",
      url: "/job_occupation",
      signal,
    })
    if (res?.status === 200) {
      if (typeof window !== "undefined") {
        window.sswOccupations = res?.data?.data
      }
      return res?.data?.data
    }
  } catch (error) {
    console.log({ failed_fetch_ssw_occupations: error })
  }
}
export const sswOccupationsAtom = atomWithQuery((get) => ({
  queryKey: setupKeys.sswOccupations,
  queryFn: fetchSswOccupations,
  ...setupOptions,
}))
export const useSswOccupationsQuery = () => {
  const sswOccupationsQuery = useQuery(
    setupKeys.sswOccupations,
    fetchSswOccupations,
    {
      setupOptions,
    }
  )
  return sswOccupationsQuery
}

export const useParseIndustry = () => {
  const { data: titpIndustries } = useTitpIndustriesQuery()
  const { data: sswIndustries } = useSswIndustriesQuery()

  const handleParseIndustry = useCallback(
    (slug, type) => {
      if (type === "ssw") {
        let index = sswIndustries?.findIndex((s) => s?.slug === slug)
        if (index !== -1) {
          return sswIndustries?.[index]?.industry_ind
        }
      } else {
        let index = titpIndustries?.findIndex((s) => s?.slug === slug)
        if (index !== -1) {
          return titpIndustries?.[index]?.industry_ind
        }
      }
    },
    [titpIndustries, sswIndustries]
  )

  return handleParseIndustry
}

export const useParseOccupation = () => {
  const { data: titpOccupations } = useTitpOccupationsQuery()
  const { data: sswOccupations } = useSswOccupationsQuery()

  const handleParseOccupation = useCallback(
    (slug, type) => {
      if (type === "ssw") {
        let index = sswOccupations?.findIndex((o) => o?.slug === slug)
        if (index !== -1) {
          return sswOccupations?.[index]?.occupation_ind
        }
      } else {
        let index = titpOccupations?.findIndex((o) => o?.slug === slug)
        if (index !== -1) {
          return titpOccupations?.[index]?.occupation_ind
        }
      }
    },
    [titpOccupations, sswOccupations]
  )

  return handleParseOccupation
}

export const epaIndustries = [
  {
    slug: "nurse",
    jp: "Kango-shi",
    id: "Kango-shi (Nurse)",
  },
  {
    slug: "elder",
    jp: "Kaigo",
    id: "Kaigo (Perawat Lansia)",
  },
]

export const epaIndustriesAtom = atom(epaIndustries)

export const handleParseEpa = (slug, lang) => {
  if (slug) {
    let index = epaIndustries?.findIndex((ep) => ep?.slug === slug)
    if (index !== -1) {
      return epaIndustries?.[index][lang]
    }
  }
}

export const useParseEpa = () => {
  return handleParseEpa
}
