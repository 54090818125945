import React, { useCallback, useEffect, useRef } from "react"
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai"
import { io } from "socket.io-client"
import { useSession } from "next-auth/react"

import { event } from "../utils/event"

import { useUserQuery } from "../atoms/userAtom"

import {
  chatChannelUrl,
  chatRoomAtom,
  socketChatAtom,
  unreadChatsAtom,
  addUnreadChatAtom,
  updateRoomParticipantsAtom,
  updateTypersAtom,
  updateRoomLatestMessage,
  mutateChatsAtom,
  activeChatRoomIdAtom,
} from "../atoms/chatAtom"
import { useQuery } from "react-query"
import axios from "axios"

const useSetupSocketChat = () => {
  const [globalSocketChat, setGlobalSocketChat] = useAtom(socketChatAtom)

  const { data: session, status } = useSession()

  // To prevent making new connection on useEffect everytime session updated
  // const [chatRef, setChatRef] = useAtom(chatRefAtom)
  const chatRef = useRef(null)

  useEffect(() => {
    if (
      chatRef?.current ||
      status === "loading" ||
      status === "unauthenticated" ||
      !session
    ) {
      // console.log({ CURRENT: chatRef?.current, session, status })

      if (chatRef?.current?.disconnected) {
        chatRef?.current?.connect()
      }

      return
    }

    const socketChat = io(chatChannelUrl, {
      autoConnect: false,
      query: {
        userId: session?.user?._id,
        role: session?.role ?? "candidate",
        apiToken: session?.token,
      },
    })

    if (socketChat) {
      window.socketChat = socketChat
      chatRef.current = socketChat
      setGlobalSocketChat(socketChat)
    }

    // console.log({ SOCKET_SETUP: socketChat })

    return () => {
      console.log("💬 CLOSING...")
      socketChat.close()
    }
  }, [session, status, setGlobalSocketChat])

  return globalSocketChat
}

// STATIC HANDLERS
function onConnectError(error) {
  console.log({ chatConnectError: error })
}
function onError(error) {
  console.log({ socketChatError: error })
}

const fetchSession = async () => {
  try {
    const res = await axios({ method: "GET", url: "/api/auth/session" })
    console.log({ res })
    return res?.data
  } catch (error) {
    return null
  }
}
export function useSessionQuery(options) {
  // const value = React.useContext(SessionContext)
  const { required, onUnauthenticated } = options ?? {}
  // const requiredAndNotLoading = required && value.status === "unauthenticated"

  const sessionQuery = useQuery(["next-auth-session-query"], fetchSession)

  // React.useEffect(() => {
  //   if (requiredAndNotLoading) {
  //     const url = `/api/auth/signin?${new URLSearchParams({
  //       error: "SessionRequired",
  //       callbackUrl: window.location.href,
  //     })}`
  //     if (onUnauthenticated) onUnauthenticated()
  //     else window.location.href = url
  //   }
  // }, [requiredAndNotLoading, onUnauthenticated])

  // if (requiredAndNotLoading) {
  //   return {
  //     data: value.data,
  //     update: value.update,
  //     status: "loading",
  //   }
  // }

  return sessionQuery
}

export const SocketChatChannel = React.memo(() => {
  const { data: me } = useUserQuery()
  const { data: session } = useSession()

  const socketChat = useSetupSocketChat()

  const setChatRoom = useSetAtom(chatRoomAtom)
  const setRoomLatestMessage = useSetAtom(updateRoomLatestMessage)
  const activeRoomId = useAtomValue(activeChatRoomIdAtom)
  useEffect(() => {
    console.log({ SOCKET_INIT: activeRoomId })
  }, [activeRoomId])

  const updateChats = useSetAtom(mutateChatsAtom)

  const setUnreadChats = useSetAtom(unreadChatsAtom)
  const addUnreadChat = useSetAtom(addUnreadChatAtom)
  const updateRoomParticipants = useSetAtom(updateRoomParticipantsAtom)
  const updateTypers = useSetAtom(updateTypersAtom)

  const onConnect = useCallback(() => {
    console.log({ "💬 CONNECTED": socketChat })
  }, [socketChat])
  const onDisconnect = useCallback(() => {
    console.log({ "💬 DISCONNECTED": socketChat })
  }, [socketChat])

  const onJoinOwnRoom = useCallback(
    ({ roomId, room, unreadChats }) => {
      if (roomId === session?.user?._id) {
        if (room) {
          setChatRoom({ ...room, status: "done" })
        }
        if (unreadChats) {
          setUnreadChats(unreadChats)
        }
        // socketChat.emit(event.greet, { roomId }, ({ unreadChats, rooms }) => {
        //   console.log({ OWN_ROOM: rooms })
        //   if (unreadChats) {
        //     setUnreadChats(unreadChats)
        //   }
        // })
      }
    },
    [session]
  )

  const onEnteringRoom = useCallback(({ roomId, users }) => {
    updateRoomParticipants({ roomId, users })
  }, [])
  const onLeavingRoom = useCallback(({ roomId, users }) => {
    updateRoomParticipants({ roomId, users })
  }, [])

  const onStartTyping = useCallback(({ roomId, users }) => {
    updateTypers({ roomId, users })
  }, [])
  const onStopTyping = useCallback(({ roomId, users }) => {
    updateTypers({ roomId, users })
  }, [])

  const onNewChat = (newChat) => {
    // Add to chat history
    // Add to unread chat if it's from another sender
    // console.log({ newChat })
    updateChats(newChat)
    if (me?._id !== newChat?.sender?._id) {
      addUnreadChat({
        roomId: newChat?.roomId,
        chatId: newChat?._id,
        userId: me?._id,
        roomType: newChat?.roomType,
        created_at: newChat?.created_at,
        updated_at: newChat?.updated_at,
      })
    }
  }

  const onUpdateRoom = (room) => {
    setRoomLatestMessage(room)
  }

  const onRefetchRoom = useCallback(() => {
    console.log("refetching...")
    socketChat.emit(
      event.greet,
      { roomId: me?._id },
      ({ rooms, unreadChats }) => {
        console.log({ REFETCH_ROOM: rooms })
        if (rooms) {
          setChatRoom(rooms?.[0])
        }
        if (unreadChats) {
          setUnreadChats(unreadChats)
        }
      }
    )
  }, [me])

  useEffect(() => {
    if (!socketChat) {
      console.warn("NO_SOCKET_CHAT")
      return
    }

    // Connected to socket chat
    socketChat.on(event.own, onJoinOwnRoom)

    // Connected user entering a room / opening or leaving the chat page
    socketChat.on(event.room.enter, onEnteringRoom)
    socketChat.on(event.room.leave, onLeavingRoom)

    // User start/stop typing the message
    socketChat.on(event.room.startTyping, onStartTyping)
    socketChat.on(event.room.stopTyping, onStopTyping)

    socketChat.on(event.room.newChat, onNewChat)

    socketChat.on(event.room.update, onUpdateRoom)

    socketChat.on(event.room.refetch, onRefetchRoom)

    // Admin connected to chat channel
    socketChat.on("connect", onConnect)
    socketChat.on("disconnect", onDisconnect)
    socketChat.io.on("connect_error", onConnectError)
    socketChat.io.on("error", onError)

    if (session?.token && session?.user && socketChat.disconnected) {
      // console.log({ CONNECTING: socketChat })
      socketChat.connect()
    }

    // Cleanup
    return () => {
      socketChat.off(event.room.enter, onEnteringRoom)
      socketChat.off(event.room.leave, onLeavingRoom)

      socketChat.off(event.room.startTyping, onStartTyping)
      socketChat.off(event.room.stopTyping, onStopTyping)

      socketChat.off("connect", onConnect)
      socketChat.off("disconnect", onDisconnect)
      socketChat.io.off("connect_error", onConnectError)
      socketChat.io.off("error", onError)
    }
  }, [socketChat, me, session])

  return null
})
