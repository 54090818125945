import React, { useState, useRef } from "react"
import axios from "axios"
import { HOST } from "../pages/_app"
import { useAtomValue } from "jotai"
import { apiTokenAtom } from "../atoms/userAtom"

const CDN_HOST = process.env.NEXT_PUBLIC_CDN_HOST

export const useUploadDocument = () => {
  const token = useAtomValue(apiTokenAtom)
  const [loading, setLoading] = useState(false)

  const triggerRef = useRef()

  const triggerClick = () => {
    if (triggerRef.current) {
      triggerRef.current.click()
    }
  }

  const handlePreview = async (e, setPhoto, id, applicantId) => {
    const formData = new FormData()
    setLoading(true)
    let files = e.target.files

    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i])
      formData.append("path", `${applicantId}/applications/${id}`)
    }

    // console.log("UPLOADING FILE...")

    try {
      let data = await axios({
        method: "POST",
        url: HOST + "/upload/file",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        // console.log({ UPLOAD: res?.data })
        return res.data
      })

      if (data?.status === "success") {
        // console.log({ photo: data })
        setPhoto(CDN_HOST + data?.path)
      } else {
        // console.log({ FAILED_UPLOAD: data })
      }
    } catch (error) {
      alert("Upload document failed!")
      // console.log({ error })
    }

    triggerRef.current.value = ""

    setLoading(false)
  }

  return {
    triggerRef,
    triggerClick,
    handlePreview,
    loading,
  }
}

export default useUploadDocument
