import React from "react"

import { SocketNotifChannel } from "../hooks/useSocketNotif"
import { SocketChatChannel } from "../hooks/useSocketChat"
import { VisaProvider } from "./../hooks"

const AppProvider = React.memo(({ children }) => {
  return (
    <>
      <SocketChatChannel />
      <SocketNotifChannel />
      <VisaProvider>{children}</VisaProvider>
    </>
  )
})

AppProvider.displayName = "AppProvider"

export default AppProvider
