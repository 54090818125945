export const bulan = [
  { value: 1, label: "Januari" },
  { value: 2, label: "Februari" },
  { value: 3, label: "Maret" },
  { value: 4, label: "April" },
  { value: 5, label: "Mei" },
  { value: 6, label: "Juni" },
  { value: 7, label: "Juli" },
  { value: 8, label: "Agustus" },
  { value: 9, label: "September" },
  { value: 10, label: "Oktober" },
  { value: 11, label: "November" },
  { value: 12, label: "Desember" },
]

export const tingkatanPendidikan = [
  { label: "SD/Sederajat", value: "sd" },
  { label: "SMP/Sederajat", value: "smp" },
  { label: "SMA/Sederajat", value: "sma" },
  { label: "Diploma satu (D1)", value: "d1" },
  { label: "Diploma dua (D2)", value: "d2" },
  { label: "Diploma tiga (D3)", value: "d3" },
  { label: "Diploma empat (D4)", value: "d4" },
  { label: "Sarjana (S1)", value: "s1" },
  { label: "Magister (S2)", value: "s2" },
  { label: "Doktor (S3)", value: "s3" },
]

export const optionsSkill = [
  { id: 47, label: "Accounting" },
  { id: 94, label: "Airlines/Aviation" },
  { id: 120, label: "Alternative Dispute Resolution" },
  { id: 125, label: "Alternative Medicine" },
  { id: 127, label: "Animation" },
  { id: 19, label: "Apparel & Fashion" },
  { id: 50, label: "Architecture & Planning" },
  { id: 111, label: "Arts & Crafts" },
  { id: 53, label: "Automotive" },
  { id: 52, label: "Aviation & Aerospace" },
  { id: 41, label: "Banking" },
  { id: 12, label: "Biotechnology" },
  { id: 36, label: "Broadcast Media" },
  { id: 49, label: "Building Materials" },
  { id: 138, label: "Business Supplies & Equipment" },
  { id: 129, label: "Capital Markets" },
  { id: 54, label: "Chemicals" },
  { id: 90, label: "Civic & Social Organization" },
  { id: 51, label: "Civil Engineering" },
  { id: 128, label: "Commercial Real Estate" },
  { id: 118, label: "Computer & Network Security" },
  { id: 109, label: "Computer Games" },
  { id: 3, label: "Computer Hardware" },
  { id: 5, label: "Computer Networking" },
  { id: 4, label: "Computer Software" },
  { id: 48, label: "Construction" },
  { id: 24, label: "Consumer Electronics" },
  { id: 25, label: "Consumer Goods" },
  { id: 91, label: "Consumer Services" },
  { id: 18, label: "Cosmetics" },
  { id: 65, label: "Dairy" },
  { id: 1, label: "Defense & Space" },
  { id: 99, label: "Design" },
  { id: 132, label: "E-learning" },
  { id: 69, label: "Education Management" },
  { id: 112, label: "Electrical & Electronic Manufacturing" },
  { id: 28, label: "Entertainment" },
  { id: 86, label: "Environmental Services" },
  { id: 110, label: "Events Services" },
  { id: 76, label: "Executive Office" },
  { id: 122, label: "Facilities Services" },
  { id: 63, label: "Farming" },
  { id: 43, label: "Financial Services" },
  { id: 38, label: "Fine Art" },
  { id: 66, label: "Fishery" },
  { id: 34, label: "Food & Beverages" },
  { id: 23, label: "Food Production" },
  { id: 101, label: "Fundraising" },
  { id: 26, label: "Furniture" },
  { id: 29, label: "Gambling & Casinos" },
  { id: 145, label: "Glass, Ceramics & Concrete" },
  { id: 75, label: "Government Administration" },
  { id: 148, label: "Government Relations" },
  { id: 140, label: "Graphic Design" },
  { id: 124, label: "Health, Wellness & Fitness" },
  { id: 68, label: "Higher Education" },
  { id: 14, label: "Hospital & Health Care" },
  { id: 31, label: "Hospitality" },
  { id: 137, label: "Human Resources" },
  { id: 134, label: "Import & Export" },
  { id: 88, label: "Individual & Family Services" },
  { id: 147, label: "Industrial Automation" },
  { id: 84, label: "Information Services" },
  { id: 96, label: "Information Technology & Services" },
  { id: 42, label: "Insurance" },
  { id: 74, label: "International Affairs" },
  { id: 141, label: "International Trade & Development" },
  { id: 6, label: "Internet" },
  { id: 45, label: "Investment Banking" },
  { id: 46, label: "Investment Management" },
  { id: 73, label: "Judiciary" },
  { id: 77, label: "Law Enforcement" },
  { id: 9, label: "Law Practice" },
  { id: 10, label: "Legal Services" },
  { id: 72, label: "Legislative Office" },
  { id: 30, label: "Leisure, Travel & Tourism" },
  { id: 85, label: "Libraries" },
  { id: 116, label: "Logistics & Supply Chain" },
  { id: 143, label: "Luxury Goods & Jewelry" },
  { id: 55, label: "Machinery" },
  { id: 11, label: "Management Consulting" },
  { id: 95, label: "Maritime" },
  { id: 97, label: "Market Research" },
  { id: 80, label: "Marketing & Advertising" },
  { id: 135, label: "Mechanical Or Industrial Engineering" },
  { id: 126, label: "Media Production" },
  { id: 17, label: "Medical Device" },
  { id: 13, label: "Medical Practice" },
  { id: 139, label: "Mental Health Care" },
  { id: 71, label: "Military" },
  { id: 56, label: "Mining & Metals" },
  { id: 3131, label: "Mobile Games" },
  { id: 35, label: "Motion Pictures & Film" },
  { id: 37, label: "Museums & Institutions" },
  { id: 115, label: "Music" },
  { id: 114, label: "Nanotechnology" },
  { id: 81, label: "Newspapers" },
  { id: 100, label: "Non-profit Organization Management" },
  { id: 57, label: "Oil & Energy" },
  { id: 113, label: "Online Media" },
  { id: 123, label: "Outsourcing/Offshoring" },
  { id: 87, label: "Package/Freight Delivery" },
  { id: 146, label: "Packaging & Containers" },
  { id: 61, label: "Paper & Forest Products" },
  { id: 39, label: "Performing Arts" },
  { id: 15, label: "Pharmaceuticals" },
  { id: 131, label: "Philanthropy" },
  { id: 136, label: "Photography" },
  { id: 117, label: "Plastics" },
  { id: 107, label: "Political Organization" },
  { id: 67, label: "Primary/Secondary Education" },
  { id: 83, label: "Printing" },
  { id: 105, label: "Professional Training & Coaching" },
  { id: 102, label: "Program Development" },
  { id: 79, label: "Public Policy" },
  { id: 98, label: "Public Relations & Communications" },
  { id: 78, label: "Public Safety" },
  { id: 82, label: "Publishing" },
  { id: 62, label: "Railroad Manufacture" },
  { id: 64, label: "Ranching" },
  { id: 44, label: "Real Estate" },
  { id: 40, label: "Recreational Facilities & Services" },
  { id: 89, label: "Religious Institutions" },
  { id: 144, label: "Renewables & Environment" },
  { id: 70, label: "Research" },
  { id: 32, label: "Restaurants" },
  { id: 27, label: "Retail" },
  { id: 121, label: "Security & Investigations" },
  { id: 7, label: "Semiconductors" },
  { id: 58, label: "Shipbuilding" },
  { id: 20, label: "Sporting Goods" },
  { id: 33, label: "Sports" },
  { id: 104, label: "Staffing & Recruiting" },
  { id: 22, label: "Supermarkets" },
  { id: 8, label: "Telecommunications" },
  { id: 60, label: "Textiles" },
  { id: 130, label: "Think Tanks" },
  { id: 21, label: "Tobacco" },
  { id: 108, label: "Translation & Localization" },
  { id: 92, label: "Transportation/Trucking/Railroad" },
  { id: 59, label: "Utilities" },
  { id: 106, label: "Venture Capital & Private Equity" },
  { id: 16, label: "Veterinary" },
  { id: 93, label: "Warehousing" },
  { id: 133, label: "Wholesale" },
  { id: 142, label: "Wine & Spirits" },
  { id: 119, label: "Wireless" },
  { id: 103, label: "Writing & Editing" },
  { id: 9999, label: "Lainnya" },
]

export const workExperienceIndustries = optionsSkill
