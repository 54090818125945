import React, { useEffect } from "react"
import { useRouter } from "next/router"
import { useSession } from "next-auth/react"
import { useQuery } from "react-query"

import { fetchUser, userKeys } from "../atoms/userAtom"
import LoaderIcon from "../components/icons/LoaderIcon"

const ProtectedRoute = ({ children, required }) => {
  const router = useRouter()

  const { data: session, status } = useSession({
    required,
    onUnauthenticated: () =>
      router.push({
        pathname: "/login",
        query: { ...router?.query },
      }),
  })

  const { data: user, status: userStatus } = useQuery(
    userKeys.profile,
    fetchUser,
    { enabled: Boolean(session?.token) }
  )

  useEffect(() => {
    if (status === "loading" || userStatus === "loading") {
      return
    }

    if (required && status === "unauthenticated" && !session) {
      router.push({
        pathname: "/login",
        query: { ...router?.query },
      })
      return
    }

    if (!user && userStatus !== "loading") {
      router.push({
        pathname: "/login",
        query: { ...router?.query },
      })
      return
    }

    if (
      status === "authenticated" &&
      session?.token &&
      user &&
      !user?.onboarded &&
      router?.isReady &&
      router?.pathname !== "/dashboard/personal-data"
    ) {
      router.push("/dashboard/personal-data")
      return
    }
  }, [status, session, userStatus, user, required])

  if (
    status === "loading" ||
    status === "unauthenticated" ||
    userStatus === "loading" ||
    (userStatus === "success" &&
      !user?.onboarded &&
      router?.pathname !== "/dashboard/personal-data")
  ) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "relative",
          display: "grid",
          placeContent: "center",
        }}
      >
        <LoaderIcon style={{ width: 80, height: 80 }} />
      </div>
    )
  }

  return children
}

export default ProtectedRoute
