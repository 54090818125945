import React, { useEffect, useRef } from "react"
import { useAtomValue, useSetAtom } from "jotai"
import { io } from "socket.io-client"
import { useSession } from "next-auth/react"

import { event } from "../utils/event"

import { apiTokenAtom, useUserQuery } from "../atoms/userAtom"

import {
  notificationChannelUrl,
  notificationsAtom,
  socketNotificationAtom,
  addNotificationAtom,
  useNotificationsQuery,
} from "../atoms/notificationAtom"

export const SocketNotifChannel = React.memo(() => {
  const notifRef = useRef(null)

  const { data: session, status } = useSession()
  const { data: me } = useUserQuery()

  const apiToken = useAtomValue(apiTokenAtom)

  const setSocketNotification = useSetAtom(socketNotificationAtom)
  const setNotifications = useSetAtom(notificationsAtom)
  const addNotification = useSetAtom(addNotificationAtom)
  const { refetch: refetchNotifications } = useNotificationsQuery()

  useEffect(() => {
    if (status === "unauthenticated" || status === "loading") {
      if (notifRef?.current && !session) {
        notifRef.current.disconnect()
        console.log("🔔 DISCONNECTED...")
        return
      }

      return
    }

    if (!apiToken || notifRef?.current || !me?._id || !notificationChannelUrl) {
      return
    }

    const socketNotif = io(notificationChannelUrl, {
      query: {
        userId: me?._id,
        role: me?.role ?? "pic",
        apiToken,
      },
    })

    if (socketNotif?.disconnected) {
      socketNotif?.connect()
    }

    notifRef.current = socketNotif
    setSocketNotification(socketNotif)
    window.ioNotif = socketNotif

    socketNotif.on("connect", () => {
      console.log("🔔 CONNECTED...")
      window.socketNotif = socketNotif
      socketNotif.emit(
        event.greet,
        { roomId: me?._id },
        ({ notifications }) => {
          // console.log({ notifications })
          setNotifications(notifications)
        }
      )
    })

    socketNotif.on(event.notification.new, ({ notification }) => {
      console.log({ new: notification })
      if (notification) {
        addNotification({ notification })
        refetchNotifications()
      }
    })

    socketNotif.on("disconnect", () => {
      console.log("🔔 DISCONNECTED...")
    })

    socketNotif.io.on("connect_error", (error) => {
      console.log({ socketNotifConnectError: error })
    })

    socketNotif.io.on("error", (error) => {
      console.log({ socketNotifError: error })
    })

    socketNotif.on(event.notification.new, ({ notification }) => {
      // console.log({ notification })
    })

    return () => {
      // console.log("Cleanup...")
      // socketNotif.disconnect()
    }
  }, [apiToken, me, session, status, notificationChannelUrl])

  return null
})
